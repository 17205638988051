const ISmartSUs = {
  // address: "0x5a5a7689d2baf7e8a74fa73ea47493a0bf4deb2fcac3c962643a02c77c53a744",
  address: "0x73797475686500000000000000000000536d6172744761746553797374656d00",
  abi: [
    //this was the wrong approach, as need an SSU to purchase the ticket
    // {
    //   type: "function",
    //   name: "tuhe__executeGateAccess",
    //   inputs: [
    //     {
    //       name: "smartCharacterId",
    //       type: "uint256",
    //       internalType: "uint256",
    //     },
    //     {
    //       name: "couldAccess",
    //       type: "bool",
    //       internalType: "bool",
    //     },
    //   ],
    //   outputs: [],
    //   stateMutability: "nonpayable",
    // },
    {
      type: "function",
      name: "tuhe__executeGateTickets",
      inputs: [
        {
          name: "smartCharacterId",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "smartStorageUnitId",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "quantity",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "inventoryItemIdIn",
          type: "uint256",
          internalType: "uint256",
        }
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
    {
      type: "function",
      name: "tapticc2__Balance",
      inputs: [
        {
          name: "smartCharacterId",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "smartStorageUnitId",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "quantity",
          type: "uint256",
          internalType: "uint256",
        },
        {
          name: "inventoryItemIdIn",
          type: "uint256",
          internalType: "uint256",
        }
      ],
      outputs: [],
      stateMutability: "nonpayable",
    },
  ]
} as const;

export default ISmartSUs;
