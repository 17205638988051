import React, { ReactNode, createContext, useMemo } from "react";
import {
  Client,
  getContract,
  GetContractReturnType,
  PublicClient,
  WalletClient,
} from "viem";
import ISmartSUs from "./ISmartSUs";
import { useConnection } from "@eveworld/contexts";

interface SmartSUsContract {
  SmartSUsContract: GetContractReturnType<typeof ISmartSUs.abi, Client> | null;
}

const SmartSUsProvider = ({ children }: { children: ReactNode }) => {
  const { defaultNetwork, publicClient, walletClient } = useConnection();

  const SmartSUsContract = useMemo(
    () =>
      getContract({
        abi: ISmartSUs.abi,
        address: defaultNetwork.worldAddress,
        client: {
          public: publicClient as PublicClient,
          wallet: walletClient as WalletClient,
        },
      }),
    [publicClient, walletClient]
  );

  return (
    <SmartSUsContext.Provider value={{ SmartSUsContract }}>
      {children}
    </SmartSUsContext.Provider>
  );
};

export const SmartSUsContext = createContext<SmartSUsContract>({
  SmartSUsContract: null,
});

export default SmartSUsProvider;
