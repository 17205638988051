import { defineWorld } from "@latticexyz/world";
//import { defineERC20Module } from "@latticexyz/world-module-erc20/internal";
import { encodeAbiParameters, stringToHex } from "viem";
 
const erc20ModuleArgs = encodeAbiParameters(
  [
    { type: "bytes14" },
    {
      type: "tuple",
      components: [{ type: "uint8" }, { type: "string" }, { type: "string" }],
    },
  ],
  [stringToHex("tapticc2", { size: 14 }), [18, "TAP Coin", "TAPCOIN"]],
);

const appNamespace = "tapticc";

export default defineWorld({
  namespace: appNamespace,
  tables: {
    GateAccess: {
      schema: {
        smartCharacterId: "address",
        smartGateId: "uint256",
        couldJump: "string",
      },
      key: ["smartCharacterId", "smartGateId"],
    },
    GateTickets: {
      schema: {
        smartCharacterId: "uint256",
        smartStorageUnitId: "uint256", //where was the ticket bought
        ticketExpiry: "uint256",
      },
      key: ["smartCharacterId"],
    },
    Balances: {
      schema: {
        account: "address",
        value: "uint256", 
      },
      key: ["account"],
    },
  },
  // modules: [
  //   defineERC20Module({
  //     namespace: "tapticc",
  //     name: "TAP Coin",
  //     symbol: "TAPCOIN",
  //   }),
  // ],
  modules: [
    {
      artifactPath: "@latticexyz/world-modules/out/PuppetModule.sol/PuppetModule.json",
      root: false,
      args: [],
    },
    {
      artifactPath: "@latticexyz/world-modules/out/ERC20Module.sol/ERC20Module.json",
      root: false,
      args: [
        {
          type: "bytes",
          value: erc20ModuleArgs,
        },
      ],
    },
  ],
});
