// import React, { useEffect, useContext } from "react";
import React, { useEffect } from "react";
import { useMUD } from "../MUDContext";

import {
  useSmartObject,
  useNotification,
  // useConnection,
} from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import {
  EveLoadingAnimation,
  ErrorNotice,
  ErrorNoticeTypes,
  // EveButton,
} from "@eveworld/ui-components";

import { JSX } from "react/jsx-runtime";
import { findOwnerByAddress } from "@eveworld/utils";
import moment from "moment";
import { setup } from "../mud/setup.ts";
const { network } = await setup();

//import { SmartSUsContext } from "../src/contract/SmartSUsContext.tsx";

import WelcomePlayer from "./WelcomePlayer.tsx";
import InventoryListItem from "./InventoryListItem";
import InventoryListView from "./InventoryListView";

const EntityView = React.memo(
  () //   {
  : JSX.Element => {
    // const { SmartSUsContract } = useContext(SmartSUsContext);
    const { smartAssembly, smartCharacter, loading } = useSmartObject();
    const { notify, handleClose } = useNotification();
    const {
      network: { tables, useStore },
    } = useMUD();

    // const { GateTickets } = tables;
    // const { walletClient, publicClient } = useConnection();

    useEffect(() => {
      if (loading) {
        notify({ type: Severity.Info, message: "Loading..." });
      } else {
        handleClose();
      }
    }, [loading]);

    if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
      return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
    }

    var smartSU = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

    const key = {
      smartCharacterId: BigInt(smartCharacter.id),
    };

    const rec = network.useStore
      .getState()
      .getRecord(network.tables.GateTickets, key);

    var smartStorageUnit =
      smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

    var ephemeralInventoryList =
      smartStorageUnit.inventory.ephemeralInventoryList;

    const playerInventory = ephemeralInventoryList.find((x) =>
      findOwnerByAddress(x.ownerId, smartCharacter.address)
    );

    const inventoryList: JSX.Element[] = [];
    if (playerInventory) {
      var ephemeralInventoryItems = playerInventory.ephemeralInventoryItems;
      if (ephemeralInventoryItems) {
        ephemeralInventoryItems.forEach((ephemeralInventoryItem) => {
          inventoryList.push(
            <InventoryListItem
              ephemeralInventoryItem={ephemeralInventoryItem}
              key={ephemeralInventoryItem.itemId}
            ></InventoryListItem>
          );
        });
      } else {
        inventoryList.push(
          <li key="ephemeralInventoryItemsNotSet">
            You have no items in this SSU, deposit salt and claim your jump gate
            ticket now
          </li>
        );
      }
    }

    var dateFormatted = "";
    if (rec?.fields.ticketExpiry) {
      // var d = new Date();
      // var seconds = d.getTime() * 100;

      var secondsSinceEpoch = Math.floor(Date.now() / 1000);

      var startDate = moment(secondsSinceEpoch * 1000);
      var timeEnd = moment(Number(rec?.fields.ticketExpiry) * 1000);
      var diff = timeEnd.diff(startDate);
      var diffDuration = moment.duration(diff);

      // console.log("Total Duration in millis:", diffDuration.asMilliseconds());
      // console.log("Days:", diffDuration.days());
      // console.log("Hours:", diffDuration.hours());
      // console.log("Minutes:", diffDuration.minutes());
      // console.log("Seconds:", diffDuration.seconds());

      if (diffDuration.seconds() < 0) {
        dateFormatted = "You do not have a valid ticket for this journey.";
      } else {
        dateFormatted =
          "Your ticket expires in " +
          diffDuration.days().toString() +
          "d " +
          diffDuration.hours().toString() +
          "h " +
          diffDuration.minutes().toString() +
          "m " +
          diffDuration.seconds().toString() +
          "s ";
        ("approximately.");
      }
    }

    return (
      <>
        <EveLoadingAnimation position="diagonal">
          <div className="grid border border-brightquantum">
            <WelcomePlayer></WelcomePlayer>
            {/* <EveButton
              typeClass="primary"
              className="primary-sm"
              style={{
                width: "175px",
              }}
              onClick={() => handleGateTickets()}
              disabled={false}
              id="BuyJumpGateTicket"
            >
              Buy Jump Gate Ticket
            </EveButton> */}
          </div>
          <InventoryListView inventoryList={inventoryList}></InventoryListView>
          <div className="grid grid-cols-2 mobile:grid-cols-1 bg-crude">
            <div className="text-l my-2 text-center">
              <p>{smartSU.name}</p>
              <p> {dateFormatted}</p>
            </div>
          </div>
        </EveLoadingAnimation>
      </>
    );
  }
);

export default React.memo(EntityView);
