/*
 * Create the system calls that the client can use to ask
 * for changes in the World state (using the System contracts).
 */

import { SetupNetworkResult } from "./setupNetwork";

export type SystemCalls = ReturnType<typeof createSystemCalls>;

export function createSystemCalls(
  { useStore, tables }: SetupNetworkResult,
) {
  // const getHighScores = async () => {
  //   return useStore.getState().getRecords(tables.SmartSUs);
  // };

  return {
    // getHighScores,
  };
}
