import React, { useContext } from "react";
import { useSmartObject, useConnection } from "@eveworld/contexts";
import { useMUD } from "../MUDContext";

import { SmartSUsContext } from "../src/contract/SmartSUsContext.tsx";
//import { getKeyTuple } from "@latticexyz/protocol-parser/internal";
//import { Address } from "viem";

interface Props {
  inventoryList: JSX.Element[];
}

const InventoryListView = ({ inventoryList }: Props) => {
  //const { smartCharacter } = useSmartObject();
  const { SmartSUsContract } = useContext(SmartSUsContext);
  const { smartAssembly } = useSmartObject();
  const { walletClient, publicClient } = useConnection();

  const {
    network: { tables, useStore },
  } = useMUD();

  // const highScoreEntities = useStore((state) => {
  //   var records = Object.values(state.getRecords(tables.SmartSUs2));
  //   return records;
  // });

  // var errorMessage = "";

  // var smartCharacterIds: Address[] = [];

  // highScoreEntities.forEach((record) => {
  //   //var x = getKeyTuple(tables.SmartSUs2, record.key);
  //   smartCharacterIds.push(record.fields.smartCharacterId);
  //   //console.log(x[0]);
  // });

  //console.log(import.meta.env.VITE_TAPTICC_ADDRESS);

  return (
    <>
      <div className="grid grid-cols-2 mobile:grid-cols-1 bg-crude">
        <div className="text-l my-2 text-center">
          You have the following items safely stored in this SSU:
        </div>
        <div className="contents empty-span-full text-xs">
          <table style={{ width: "100%" }}>
            <tbody>{inventoryList}</tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default React.memo(InventoryListView);
