/*
 * This file sets up all the definitions required for a MUD client.
 */

import { setupNetwork } from "./setupNetwork";
//import { createClientComponents } from "./createClientComponents";
import { createSystemCalls } from "./createSystemCalls";


export type SetupResult = Awaited<ReturnType<typeof setup>>;

export async function setup() {
  const network = await setupNetwork();
  // const components = createClientComponents(network);
  // const systemCalls = createSystemCalls(network, components);
  const systemCalls = createSystemCalls(network);

  return {
    network,
    //components,
    systemCalls,
  };
}