import React, { useState } from "react";

import { useSmartObject, useConnection } from "@eveworld/contexts";
import { useMUD } from "../MUDContext";
import { setup } from "../mud/setup.ts";
import { Address, Hex } from "viem";

const { network } = await setup();

const WelcomePlayer = React.memo(() => {
  const { smartCharacter } = useSmartObject();
  useConnection();

  const {
    network: { tables, useStore },
  } = useMUD();

  if (smartCharacter === undefined) return <></>;
  if (smartCharacter.name === "") return <></>;

  const [welcomeMessage, setState] = useState("Welcome " + smartCharacter.name);

  // const key = {
  //   account: smartCharacter.address,
  // };
  // const rec = useStore((state) => state.getRecord(tables.Balances, key));

  // const key = {
  //   smartCharacterId: smartCharacter.id,
  // };

  const getRecords = network.useStore
    .getState()
    .getRecords(network.tables.Balances);

  console.log(getRecords.length);

  // getRecords.forEach((record) => {
  //   //var x = getKeyTuple(tables.SmartSUs2, record.key);
  //   if (record.key.toString() == smartCharacter.address) {
  //     console.log(record.value);
  //   }

  //   console.log(record.id);
  // });

  return (
    <>
      <div
        className="Quantum-Container font-semibold grid grid-cols-3 gap-2"
        id="SmartObject-Actions"
      >
        <div
          className="font-normal !py-4 text-center bg-crude"
          id="error-message"
        >
          {welcomeMessage}
        </div>
      </div>

      <div
        className="Quantum-Container font-normal text-xs !py-4 bg-crude"
        id="instructions-description"
      >
        Access time is paid for with Salt. Drop some salt into the left hand
        Inventory area, and then click on the Claim button. Corp members will
        receive double duration in return for their salt o7
      </div>
    </>
  );
});

export default React.memo(WelcomePlayer);
